.detail {
	font-size: 0.9rem;
	line-height: 150%;
	font-weight: 500;

	&.size--large {
		font-size: 1.1rem;
	}

	&.weight--bold {
		font-weight: bold;
	}

	&.label {
		display: flex;
		justify-content: space-between;
	}

}