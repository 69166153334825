#animation {
	background: linear-gradient(135deg, #FC5630 0%, #FF3F56 15%, #861388, #16BAC5);
	background-size: 500% 500%;
	animation: gradient 15s linear infinite
}

@keyframes gradient {
	0% {
		background-position: 0% 15%;
	}
	50% {
		background-position: 100% 85%;
	}
	100% {
		background-position: 0% 15%;
	}
}