#loadBackground {
	background: linear-gradient(135deg, #FC5630 0%, #FF3F56 15%, #861388, #16BAC5);
	background-size: 500% 500%;
	animation: gradient 8s ease-in-out infinite
}

@keyframes gradient {
	0% {
		background-position: 0% 15%;
	}
	50% {
		background-position: 100% 85%;
	}
	100% {
		background-position: 0% 15%;
	}
}

#firstImage {
	animation: firstImageAnimation 3s ease-in-out infinite;
}

#secondImage {
	position: absolute;
	top: 0;
	left: 0;
	animation: secondImageAnimation 3s ease-in-out infinite;
  }
  
  @keyframes firstImageAnimation {
	0%, 100% {
	  transform: scale(0.75);
	  rotate: 0deg;
	  opacity: 0.8;
	}
	50% {
	  transform: scale(0.9);
	  rotate: 3deg;
	  opacity: 1;
	}
  }

  @keyframes secondImageAnimation {
	0%, 100% {
	  transform: scale(0.7);
	  opacity: 0.8;
	}
	50% {
	  transform: scale(0.9);
	  opacity: 1;
	}
  }